<template>
    <div>
        <!-- {{data==''?'空数据':data}}
        {{data2}} -->
    </div>
</template>

<script>
    /**
     * 微信网页授权
     */
    import {get_gzh_user} from "@/request/api";
    import {Toast} from "vant";

    export default {
        // name: 'WxAuth',
        data() {
            return {
                data: "",
                data2: ""
            };
        },

        mounted: function () {
            // this.data = this.$route.query.code;
            // 如果连接中有微信返回的 code，则用此 code 调用后端接口，向微信服务器请求用户信息
            // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
      

            if (this.$route.query.code) {
                let code = this.$route.query.code;
                this.data = code;
               
                get_gzh_user({code: code})
                    .then(res => {
                        // this.data = "调用了接口返回then,res.code:"+res.code;
                        if (res.code == 1) {
                            this.$store.dispatch("login",res.data.token);
                            //获取上一页路由
                            let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
                            if(redirectUrl==''||redirectUrl==undefined){
                                redirectUrl = "http://"+window.location.host+"/user";
                            }
                            Toast("授权成功,即将返回上一页");
                            let that = this;
                            //返回上一页
                            setTimeout(function () {
                                console.log("跳转到"+redirectUrl);
                                that.$router.replace(redirectUrl);
                            }, 300);

                        } else {
                            this.data = res.msg;
                        }
                    })
                    .catch(err => {
                        // this.data = "调用了接口返回catch,error:"+err;
                        //失误报错
                        this.$dialog
                            .confirm({
                                title: "授权失败",
                                message: "是否重新发起授权申请"
                            })
                            .then(() => {
                                // on confirm
                                let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
                                if(redirectUrl==''||redirectUrl==undefined){
                                    redirectUrl = "http://"+window.location.host+"/user";
                                }
                                this.$store.dispatch("wechatLogin",redirectUrl);
                               // this.$router.replace(redirectUrl);
                            })
                            .catch(() => {
                                // on cancel

                            });
                        // Toast("授权成功,即将返回上一页");
                    });
            } else {
                this.$toast("授权失败,code值为空");
                // Toast("授权成功,即将返回上一页");
                //  this.$router.replace('/')
            }
        }
    };
</script>
